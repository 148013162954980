import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStaffItem } from "../../../redux/slices/cartSlice";
import { Box, Button, Card, Tab, Tabs, Typography } from "@mui/material";
import { AppSettings } from "../../../customization";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box
                sx={{
                  p: 3,
                  display: 'flex',
                  flexFlow: 'column nowrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
            >
              {children}
            </Box>
        )}
      </div>
  );
}

function Pricing({ pricing, data }) {
  // const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedStaff = useSelector((state) => state.cartSlice.selectedStaff);

  const { style, cardsConfig } = AppSettings

  const isIList = selectedStaff.includes(data);

  const [value, setValue] = useState(0);
  const [title, setTitle] = useState('');
  const handleChange = (event, newValue) => setValue(newValue);

  const existingStaff = selectedStaff.find((staff) => staff.employee_id === data.employee_id);

  useEffect(() => {
    if (existingStaff) {
      setTitle('Already in cart');
    } else {
      setTitle('Add to interview list');
    }
  }, [existingStaff, selectedStaff]);

  const handleBookInterview = () => {
    if (existingStaff) {
      return;
    }
    dispatch(createStaffItem(data));
  }


  return (
      <Card
          square={style.paperSquare}
          sx={{
            backgroundColor: 'white',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'space-between',
          }}
      >
        <Tabs aria-label="tabs" value={value} onChange={handleChange} variant="fullWidth">
          <Tab label={'Minimal'}/>
          <Tab label={'part-time'}/>
          <Tab label={'full-time'}/>
        </Tabs>

        {pricing.length &&
            pricing.map(({ title, value: price, currency, hours, disabled }, index) => (
                <TabPanel key={`${value}${index}`} value={value} index={index}>
                  <Typography
                      variant={'h2'}
                      color="primary"
                      sx={{
                        '&:first-letter': {
                          fontSize: '.75em',
                          verticalAlign: 'top',
                        },
                        // fontFamily: 'unset',
                        // fontWeight: '800',
                        fontVariationSettings: `'wdth' 100, 'wght' 800`,
                      }}
                  >
                    {`${currency}${price * cardsConfig.priceCoeff}`}
                  </Typography>
                  <Typography variant="body2">{`per month (${hours} hours)`}</Typography>
                  <Typography variant="caption">{`~ ${currency}${price * cardsConfig.priceCoeff / hours} per hour`}</Typography>
                </TabPanel>
            ))}

        <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={handleBookInterview}
            color={isIList ? 'success' : 'primary'}
            sx={{
              // borderTop: '1px solid #ccc',
              borderRadius: 'unset',
            }}
        >
          {title}
        </Button>

      </Card>
  )
}

export { Pricing }
