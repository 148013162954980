import logoFull from '../src/assets/logo_svg_full.svg'

// Departments IDs:
//    DESIGNERS: 13
//    DEVELOPERS: 36
//    MANAGERS: 23
//    MARKETERS: 14
//    VIDEOGRAPHERS: 37


// id: 4, name: 'Social Media Manager',
// id: 5, name: 'Back End Developer',
// id: 6, name: 'Front End Developer',
// id: 7, name: 'Content manager',
// id: 10, name: 'Video Editor',
// id: 14, name: 'Project manager',
// id: 15, name: 'QA',
// id: 17, name: 'Lead generator',
// id: 18, name: 'Sales Manager',
// id: 20, name: 'Personal Assistant',
// id: 25, name: 'Illustrator',
// id: 28, name: 'Graphic Designer',
// id: 29, name: 'UI UX designer',
// id: 32, name: 'SEO manager',
// id: 49, name: 'Financial manager',
// id: 51, name: 'Recruiter',
// id: 71, name: 'Full Stack Developer',

export const AppSettings = {
  style: {
    main: {
      // HEX color
      backgroundColor: '#ffdc38',
      // HEX color
      primaryColor: '#5d5d5d',
      // HEX color
      secondaryColor: '#e4a111',
      // string
      boxShadow: '0 10px 25px -5px rgba(0,0,0,.15)',
      // number
      borderRadius: '0px',
      // string
      logo: logoFull
    },
    card: {
      // css property = background
      avatarBackground: 'linear-gradient(180deg,#ffdd3c,#e4a111)',
      // HEX color or RGB
      chipBorderColor: '#5a5a5a',
      // HEX color or RGB
      chipTextColor: '#5a5a5a',
    },
    cv: {
      // css property = background
      cvAvatarBackground: 'linear-gradient(180deg,#ffdd3c,#e4a111)',
    },
    cart: {
      // css property = background
      formBackground: '#FCB900',
      // HEX color or RGB
      bookingListBorderColor: '#000000',
    },
    footer: {
      // HEX color or RGB
      FooterBackgroundColor: '#ffffff',
      // HEX color or RGB
      BtnBGColor: 'black',
      // HEX color or RGB
      filterBtnBGColor: '#5a5a5a',
    },
  },

  filter: {
    department: [37, 13],
    position: [],
    langs: []
  },
  cardsConfig: {
    // number
    priceCoeff: 1
  },
  // string
  affiliateCode: '',
}

// export const AppSettings = {
//     style: {
//         // HEX color
//         backgroundColor: '#ffdc38',
//         // HEX color
//         footerBGColor: '#ffffff',
//         // HEX color
//         footerBtnBGColor: '#000000',
//         // HEX color
//         primaryColor: '#2196f3',
//         secondaryColor: '#FB8C00',
//         // string
//         boxShadow: '0 0.2rem 1.5rem -0.3rem rgba(0,0,0,.2)',
//         avatarBackground: 'linear-gradient(180deg,#ffdd3c,#e4a111)',
//         cvAvatarBackground: 'linear-gradient(180deg,#ffdd3c,#e4a111)',
//         // number
//         borderRadius: 0
//     },
//
//     cardsConfig: {
//         priceCoeff: 3
//     },
//
//     // Departments IDs:
//     //    DESIGNERS: 13
//     //    DEVELOPERS: 36
//     //    MARKETERS: 14
//     //    SALES MANAGERS: 21
//     //    VIRTUAL ASSISTANTS: 23
//
//     filter: {
//         department: [36]
//     }
// }
