import { Chip, Zoom } from "@mui/material";

export default function Responsibilities({ workExperiences }) {

  console.log('workExperiences', workExperiences)

  const allResponsibilities = workExperiences.reduce((acc, experience) => {
    if (experience.experience_tags) {
      acc.push(...experience.experience_tags);
    }
    return acc;
  }, []);

  const uniqueResponsibilities = [...new Set(allResponsibilities)];

  return (
      <>
        {uniqueResponsibilities.map((responsibility, index) => {
          const formattedResponsibility =
              responsibility.charAt(0).toUpperCase() + responsibility.slice(1);
          return (
              <Zoom in key={index}>
                <Chip
                    label={formattedResponsibility}
                    variant="outlined"
                    size={"small"}
                    sx={{
                      // fontFamily: "unset",
                      // fontWeight: "450",
                      fontVariationSettings: `'wdth' 88, 'wght' 500`,
                    }}
                />
              </Zoom>
          );
        })}
      </>
  );
}
