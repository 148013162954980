import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Fade,
  Popper,
  Stack,
  Typography, useTheme,
  Zoom,
} from "@mui/material";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import SpaLink from "../SpaLink/SpaLink";
import { EmpSkills } from "./components/EmpSkills";
import { AppSettings } from "../../customization";

function EmployeeCard({ person: emp, small }) {
  const { style, cardsConfig } = AppSettings
  const theme = useTheme();

  const [pricing, setPricing] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const parsePricing = useCallback((data) => {
    const pricingArray = [];
    if (data.full_price) {
      pricingArray.push({
        name: "full-time",
        symbol: data.full_symbol ? data.full_symbol : "",
        price: data.full_price,
        hours: 160,
      });
    }
    if (data.part_price) {
      pricingArray.push({
        name: "part-time",
        symbol: data.full_symbol ? data.full_symbol : "",
        price: data.part_price,
        hours: 80,
      });
    }
    if (data.min_price) {
      pricingArray.push({
        name: "minimal",
        symbol: data.full_symbol ? data.full_symbol : "",
        price: data.min_price,
        hours: 40,
      });
    }
    return pricingArray;
  }, []);

  useEffect(() => {
    setPricing((prev) => parsePricing(emp));
  }, [emp, parsePricing]);

  const popoverContent = useMemo(() => {
    return (
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              color: 'common.white',
            }}
        >
          {pricing.length > 0 ? (
              pricing.map((p, i, { length: len }) => {
                const rate = Number(emp.sum_rate_rh);
                const isUnavailablePrice = emp.sum_rate_rh
                    ? rate < 1 && i === 0 && len >= 3
                    : false;
                return (
                    <Box
                        key={p.name}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '0.3rem',
                          padding: `0 0.5rem`,
                          borderRight: `1px solid white`,
                          '&:first-child': {
                            paddingLeft: 0,
                          },
                          '&:last-child': {
                            border: 'none',
                            paddingRight: 0,
                          },
                          '& > h5': {
                            textTransform: 'capitalize',
                            textAlign: 'center',
                          },
                          '& > p': {
                            textAlign: 'center',
                          },
                          '&.crossed': {
                            textDecoration: 'line-through',
                            color: 'rgba(255, 255, 255, 0.7)',
                          },
                        }}
                        className={isUnavailablePrice ? 'crossed' : ''}
                    >
                      <Typography variant="subtitle1" sx={{
                        textTransform: 'capitalize',
                      }}>{p.name}</Typography>
                      <Typography variant="body1">
                        {`${p.symbol} ${p.price * cardsConfig.priceCoeff}`}
                      </Typography>
                    </Box>
                );
              })
          ) : (
              <Box>
                <Typography variant="body1">
                  Sorry, there is no pricing for an employee
                </Typography>
              </Box>
          )}
        </Box>
    );
  }, [cardsConfig.priceCoeff, emp.sum_rate_rh, pricing]);

  return small ? (
      <Zoom in={true} timeout={300} unmountOnExit>
        <Card
            component={"article"}
            variant="outlined"
            sx={{
              minWidth: '20rem',
              width: '20rem',
              maxWidth: '30%',
              flexGrow: '1',
              lineHeight: '1'
            }}
        >
          <CardContent
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "stretch",
                gap: "1rem",
                justifyContent: "flex-start",
              }}
          >
            <Stack
                component={"section"}
                sx={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
            >
              <Box
                  component={"picture"}
                  sx={{
                    alignItems: "center",
                    aspectRatio: 1,
                    background: `${style.card.avatarBackground}` || 'linear-gradient(90deg,#0f8,#61efff)',
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    overflow: "hidden",
                    userSelect: "none",
                    width: "5rem",
                    transition: "all .2s ease-in-out",
                  }}
              >
                <CardMedia
                    component="img"
                    image={emp.image_url}
                    alt={`${emp.short_name} - ${emp.position_name}`}
                    sx={{
                      userSelect: "none",
                      filter: "drop-shadow(.8rem .8rem .5rem rgba(0,0,0,.2))",
                      transition: "all .2s ease-in-out",
                      "&:hover": {
                        scale: "1.1",
                      },
                    }}
                />
              </Box>
              <Chip
                  icon={<FingerprintIcon color={"primary"}/>}
                  label={emp.employee_id}
                  variant={"outlined"}
                  sx={{
                    position: "absolute",
                    bottom: "-.5rem",
                    width: '100px',
                    backgroundColor: "rgba(255,255,255,.98)",
                    fontVariationSettings: `'wdth' 100, 'wght' 700`,
                  }}
                  size={"small"}
              />
            </Stack>
            <Stack
                component={"section"}
                flexGrow={1}
                sx={{
                  justifyContent: "center",
                  gap: ".5rem",
                }}
            >
              <Typography
                  variant={"h5"}
                  align={"center"}
                  sx={{
                    fontSize: '1rem',
                    fontVariationSettings: `'wdth' 95,'wght' 700`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
              >
                {emp.short_name}
                <Chip
                    label={`${emp.full_symbol}
				                                    ${Number(emp.sum_rate_rh) === 1
                        ? (emp.full_price * cardsConfig.priceCoeff)
                        : (emp.part_price * cardsConfig.priceCoeff)}`}
                    size={"small"}
                    sx={{
                      padding: ".5rem",
                      backgroundColor: `#ffffff`,
                      fontVariationSettings: `'wdth' 100, 'wght' 700`,
                      fontSize: "1rem",
                      color: `${
                          Number(emp.sum_rate_rh) >= 1 || emp.sum_rate_rh === null
                              ? theme.palette.primary.main
                              : theme.palette.secondary.main
                      }`,
                      "& :first-letter": {
                        fontSize: ".75em",
                        verticalAlign: "top",
                      },
                      "& :last-child": {
                        overflow: "visible",
                      },
                      userSelect: "none",
                    }}
                ></Chip>
              </Typography>
              <Typography variant={"caption"} lineHeight={1}>
                {emp.position_name}
              </Typography>
            </Stack>
          </CardContent>
          <CardActions
              sx={{
                width: "100%",
                padding: 0,
                borderTop: `solid 1px #f1f1f1`,
              }}
          >
            <SpaLink
                to={`/${emp.slug}`}
                sx={{
                  width: "100%",
                }}
            >
              <Button
                  size="medium"
                  fullWidth
                  sx={{
                    borderRadius: "0",
                  }}
              >
                Learn More
              </Button>
            </SpaLink>
          </CardActions>
        </Card>
      </Zoom>
  ) : (
      <Zoom in={true} timeout={300} unmountOnExit>
        <Card
            component={"article"}
            sx={{
              width: "20rem",
              aspectRatio: "0.66",
              display: "flex",
              flexFlow: "column nowrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
        >
          <Box
              component={"header"}
              sx={{
                width: "100%",
                minHeight: "3rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: ".5rem",
              }}
          >
            <Chip
                aria-owns={!!anchorEl ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                label={`
						${emp.full_symbol}
						${Number(emp.sum_rate_rh) === 1
                    ? (emp.full_price * cardsConfig.priceCoeff)
                    : (emp.part_price * cardsConfig.priceCoeff)}`}
                size={"small"}
                sx={{
                  padding: ".5rem",
                  backgroundColor: `#ffffff`,
                  fontVariationSettings: `'wdth' 100, 'wght' 700`,
                  fontSize: "1rem",
                  color: `${
                      Number(emp.sum_rate_rh) >= 1 || emp.sum_rate_rh === null
                          ? theme.palette.primary.main
                          : theme.palette.secondary.main
                  }`,
                  "& :first-letter": {
                    fontSize: ".75em",
                    verticalAlign: "top",
                  },
                  "& :last-child": {
                    overflow: "visible",
                  },
                  userSelect: "none",
                  cursor: "pointer",
                }}
                onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
            ></Chip>
            <Popper
                id="mouse-over-popover"
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                placement="bottom-start"
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                transition
                sx={{
                  pointerEvents: "none",
                }}
            >
              {({ TransitionProps }) => (
                  <Fade {...TransitionProps}>
                    <Box
                        sx={{
                          padding: ".5rem .7rem",
                          bgcolor: "rgba(0, 0, 0, .6)",
                          borderRadius: "1rem",
                        }}
                    >
                      {popoverContent}
                    </Box>
                  </Fade>
              )}
            </Popper>
            <Chip
                variant="outlined"
                size={"small"}
                label={`${
                    Number(emp.sum_rate_rh) >= 1 || emp.sum_rate_rh === null
                        ? "Full"
                        : "Part"
                } time charged`}
                sx={{
                  padding: ".5rem",
                  borderColor: `${
                      Number(emp.sum_rate_rh) >= 1 || emp.sum_rate_rh === null
                          ? theme.palette.primary.main
                          : theme.palette.secondary.main
                  }`,
                  color: `${
                      Number(emp.sum_rate_rh) >= 1 || emp.sum_rate_rh === null
                          ? theme.palette.primary.main
                          : theme.palette.secondary.main
                  }`,
                  fontVariationSettings: `'wdth' 90, 'wght' 500`,
                }}
            />
          </Box>

          <CardActionArea
              sx={{
                borderRadius: "0",
                "& .MuiCardActionArea-focusHighlight": {
                  backgroundColor: theme.palette.primary.main,
                },
                "& .MuiTouchRipple-root": {
                  opacity: 0.1,
                },
              }}
          >
            <SpaLink to={`/${emp.slug}`} sx={{
              borderRadius: "0"
            }}>
              <CardContent
                  sx={{
                    width: "100%",
                    padding: "0 0 .5rem 0",
                    borderRadius: "0"
                  }}
              >
                <Stack
                    component={"section"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexFlow: "column nowrap",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      padding: ".5rem 0 1rem 0",
                    }}
                >
                  <Box
                      component={"picture"}
                      sx={{
                        width: "12rem",
                        aspectRatio: 1,
                        display: "flex",
                        borderRadius: "50%",
                        overflow: "hidden",
                        justifyContent: "center",
                        alignItems: "center",
                        background: `${style.card.avatarBackground}` || "linear-gradient(90deg,#0f8,#61efff)",
                        transition: "all .2s ease-in-out",
                        userSelect: "none",
                      }}
                  >
                    <CardMedia
                        component="img"
                        image={emp.image_url}
                        alt={`${emp.short_name} - ${emp.position_name}`}
                        sx={{
                          userSelect: "none",
                          filter: "drop-shadow(.8rem .8rem .5rem rgba(0,0,0,.2))",
                          transition: "all .2s ease-in-out",
                          "&:hover": {
                            scale: "1.1",
                          },
                        }}
                    />
                  </Box>
                  <Chip
                      icon={<FingerprintIcon color={"primary"}/>}
                      label={emp.employee_id}
                      sx={{
                        padding: ".5rem",
                        position: "absolute",
                        bottom: ".5rem",
                        backgroundColor: "rgba(255,255,255,.98)",
                        boxShadow: "0 .1rem .3rem rgba(0,0,0,.1)",
                        // fontFamily: "unset",
                        // fontWeight: "700",
                        fontVariationSettings: `'wdth' 100, 'wght' 700`,
                      }}
                  />
                </Stack>
                <Stack component={"section"} width={"100%"} flexGrow={1}>
                  <Typography
                      variant={"h5"}
                      align={"center"}
                      // fontFamily={"unset"}
                      sx={{
                        // fontWeight: "700",
                        fontVariationSettings: `'wdth' 95, 'wght' 700`,
                      }}
                  >
                    {emp.short_name}
                  </Typography>
                  <Typography
                      variant={"subtitle1"}
                      align={"center"}
                      lineHeight={1}
                  >
                    {emp.position_name}
                  </Typography>
                </Stack>
              </CardContent>
            </SpaLink>
          </CardActionArea>

          <Box
              component={"section"}
              sx={{
                width: "100%",
                flexGrow: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexFlow: "row wrap",
                alignContent: "center",
                padding: "0 1rem",
                gap: ".5rem .3rem",
                textWrap: "balance",
              }}
          >
            <EmpSkills
                empSkills={emp.skills}
            />
          </Box>
          <CardActions
              sx={{
                width: "100%",
                padding: 0,
              }}
          >
            <SpaLink
                to={`/${emp.slug}`}
                sx={{
                  width: "100%",
                }}
            >
              <Button
                  size="large"
                  fullWidth
                  sx={{
                    borderRadius: "0",
                  }}>
                Learn More
              </Button>
            </SpaLink>
          </CardActions>
        </Card>
      </Zoom>
  );
}

export { EmployeeCard };
