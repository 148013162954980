import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { Box, Button } from "@mui/material";
import { reset } from "../../../../redux/slices/filterSlice";
import CachedIcon from '@mui/icons-material/Cached';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

export function Controls({ setFilterOpen }) {
  const dispatch = useDispatch();

  const handleResetClick = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  return (
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Button
            onClick={handleResetClick}
            variant="outlined"
            color="error"
            endIcon={<CachedIcon/>}
        >
          Reset
        </Button>

        <Button
            onClick={() => setFilterOpen(false)}
            variant="outlined"
            endIcon={<CancelRoundedIcon/>}
        >
          Close
        </Button>
      </Box>
  );
}
