import {
  Stack,
} from "@mui/material";
import React from "react";
import { blue, green } from "@mui/material/colors";
import { BookingForm } from "../components/BookingForm/BookingForm";
import { BookingData } from "../components/BookingData";
import { AppSettings } from "../customization";

function CartPage() {
  const { style } = AppSettings

  return (
      <Stack flexDirection={'row'} flexWrap={'wrap'}
             sx={{ minHeight: 'calc(100vh - 6rem)', alignSelf: 'normal' }}>
        <Stack
            justifyContent={'center'}
            alignItems={'center'}
            width={'50%'}
            minWidth={'25rem'}
            padding={'1rem'}
            gap={'1rem'}
            flexGrow={1}
        >
          <BookingData/>
        </Stack>
        <Stack
            justifyContent={'center'}
            alignItems={'center'}
            minWidth={'25rem'}
            width={'50%'}
            padding={'1rem'}
            flexGrow={1}
            sx={{
              background: `${style.cart.formBackground}` || `linear-gradient(135deg, ${green["A200"]} 0%, ${blue[300]} 100%)`,
              boxShadow: '0 0 .2rem 0 rgba(0,0,0,0.2)',
            }}
        >
          <BookingForm/>
        </Stack>
      </Stack>
  );
}

export { CartPage };
