import { BookingList } from '../BookingList/BookingList';
import { useSelector } from 'react-redux';
import { Box, Button, Divider, Fade, Stack, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppSettings } from "../../customization";
import { getContrastColor } from "../../lib/helpers";

export function BookingData() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const consult = params.get('consult') ?? false;
  const orderList = useSelector((store) => store.cartSlice.selectedStaff);

  const { style } = AppSettings

  return (
      <Fade in style={{ transitionDuration: '1s' }}>
        {orderList.length ? (
            <Stack gap={'1rem'} maxWidth={'40rem'}>
              <Typography
                  fontWeight={'bold'}
                  variant={'h3'}
                  color={'primary'}
                  gutterBottom
                  sx={{
                    fontVariationSettings: `'wdth' 100, 'wght' 900`,
                    textAlign: 'center',
                  }}
              >
                Congratulations!
              </Typography>
              <Box>
                <Typography variant={'h5'} sx={{
                  fontVariationSettings: `'wdth' 95, 'wght' 700`,
                  color: getContrastColor(style.main.backgroundColor)
                }}>
                  Your remote {orderList.length > 1 ? 'team' : 'employee'} almost ready.
                </Typography>
                <Typography variant={'body1'}
                            sx={{
                              color: getContrastColor(style.main.backgroundColor)
                            }}>
                  Please check everything again, fill out the form and we will arrange a meeting with the selected
                  candidates
                </Typography>
              </Box>

              <Typography color={'primary'} padding={'0'} sx={{
                fontVariationSettings: `'wdth' 95, 'wght' 700`,
              }}>
                {`${orderList.length} employee${orderList.length > 1 ? 's' : ''} now added for interview:`}
              </Typography>
              <Box>
                <BookingList orderList={orderList}/>
              </Box>
            </Stack>
        ) : (
            <Box sx={{ width: '40vw', alignSelf: 'center', textAlign: 'center' }}>
              {/*<StepsList/>*/}
              <Typography variant='h5' sx={{ color: getContrastColor(style.main.backgroundColor) }}>
                {/*{consult ? 'Didn\'t find what you were looking for?' : 'Lorem ipsum dolor sit amet.'}*/}
                {consult ? `Didn't find what you were looking for?` : `Build Your Ultimate Remote Team`}
              </Typography>
              {/*<Typography sx={{*/}
              {/*  lineHeight: 2,*/}
              {/*  letterSpacing: '2px',*/}
              {/*  mb: '1rem',*/}
              {/*  color: getContrastColor(style.main.backgroundColor)}}>*/}
              {/*  /!*{consult*!/*/}
              {/*  /!*    ? 'Schedule meet up and we will try to find something special for you.'*!/*/}
              {/*  /!*    : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur sed pariatur quod illo deleniti exercitationem! Repudiandaiste libero cum quia. Eius quam libero nisi quisquam iure reiciendis ut aliquid ipsa tempore mollitia in, dignissimos vero suscipit doloribus officiis officia? Doloremque laboriosam ab nam mollitia quod unde modi nobis corrupti natus, earum consequuntur, odit iste ducimus quis! Ipsum perspiciatis, ipsam impedit temporibus molestias dolorum dolorem asperiores ut ab quos atque voluptatibus! Harum.'}{consult*!/*/}
              {/*      Schedule meet up and we will try to find something special for you.*/}
              {/*</Typography>*/}
              <Box>
                {consult
                    ? <Stack width={'100%'} sx={{ padding: '2rem 0' }}>
                      <Typography sx={{ width: '100%', textWrap: 'balance', textAlign: 'center' }}>
                        We will help you anyway! Please <b>fill out the form indicating a convenient meeting time for
                        you.</b> We are confident that by learning a little more about you we will find the best
                        solution.
                      </Typography>
                      <Typography color={'primary'} textAlign={'center'} fontWeight={'bold'}
                                  sx={{ width: '100%', textWrap: 'balance', paddingTop: '3dvh' }}>
                        You can also repeat the search, but now slowly and more carefully 😇
                      </Typography>
                      <Button
                          component={Link}
                          href={'/'}
                          size={'large'}
                          to={'/'}
                          variant='contained'
                          sx={{
                            marginTop: '3dvh',
                            alignSelf: 'center',
                          }}
                      >
                        explore employee catalogue
                      </Button>
                    </Stack>
                    : <Box paddingTop={'2dvh'} display={'flex'} flexDirection={'column'} gap={'1.5dvh'}>
                      <Typography
                          variant={'body1'}
                          fontWeight={'bold'}
                          textAlign={'center'}
                          gutterBottom sx={{ textWrap: 'balance' }}
                      >
                        {`You have not added any interview staff at this time. It's very easy to do:`}
                      </Typography>
                      <Box
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'flex-start'}
                          padding={'1rem'}
                          gap={'1.5dvh'}
                          border={`1px solid ${style.cart.bookingListBorderColor || '#ccc'}`}
                          borderRadius={'1rem'}
                      >
                        <Typography variant={'body1'} sx={{ textWrap: 'balance' }}>
                          {`1. Go to the `}
                          <Link href={'/'} to={'/'} style={{ fontWeight: 'bold' }}>our catalogue</Link>
                          {` page and select the employee you want to interview.`}
                        </Typography>
                        <Typography variant={'body1'} sx={{ textWrap: 'balance' }}>
                          {`2. Click on the "Add to interview" button in any CV page.`}
                        </Typography>
                        <Typography variant={'body1'} sx={{ textWrap: 'balance' }}>
                          {`3. Click on the "Book a call" button, fill out the form and just send it!`}
                        </Typography>
                      </Box>
                      <Button component={Link} to={'/'} size={'large'} variant='contained' sx={{
                        marginTop: '3dvh',
                        alignSelf: 'center',
                      }}>
                        explore employee catalogue
                      </Button>
                      <Divider sx={{ padding: '2dvh', fontWeight: 'bold' }}>OR</Divider>
                      <Typography
                          variant={'body1'}
                          color={getContrastColor(style.main.backgroundColor)}
                          fontWeight={'bold'}
                          textAlign={'center'}
                          sx={{ textWrap: 'balance' }}
                      >
                        {`Just send us a message and we will help you find the right employee for your purposes. 😉`}
                      </Typography>
                    </Box>
                }
              </Box>
            </Box>
        )}

      </Fade>
  )
      ;
}
