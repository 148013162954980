import { Breadcrumbs, capitalize, Chip } from "@mui/material";
import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useLocation, useNavigate } from "react-router-dom";

export default function CustomBreadcrumbs() {
  const location = useLocation()
  const navigate = useNavigate()
  const generateBreadcrumbs = (path) => {
    const pathParts = path.split("/").filter((part) => {
      return part.trim() !== "";
    });
    let cumulativePath = "";
    return pathParts.map((part) => {
      cumulativePath += "/" + part;
      return { path: cumulativePath, title: part };
    });
  };
  const breadcrumbs = generateBreadcrumbs(location.pathname);
  const lastBreadcrumbIndex = breadcrumbs.length - 1;

  return (
      <Breadcrumbs
          aria-label="breadcrumb"
          separator={" / "}
          color="primary"
          sx={{
            padding: "1rem",
          }}>
        <Chip
            size={`small`}
            color={`primary`}
            label={'Home'}
            onClick={() => navigate('/')}
            variant={"filled"}
            icon={<HomeIcon/>}
            sx={{
              fontVariationSettings: `'wdth' 100, 'wght' 600`,
            }}
        />
        {breadcrumbs.map((breadcrumb, index) => (
            <Chip
                size={`small`}
                color={`primary`}
                key={breadcrumb.path}
                label={capitalize(breadcrumb.title)}
                onClick={() => navigate(breadcrumb.path)}
                variant={
                  index === lastBreadcrumbIndex ? "filled" : "outlined"
                }
                icon={
                  index === lastBreadcrumbIndex ? (
                      <LocationOnIcon/>
                  ) : null
                }
                sx={{
                  fontVariationSettings: `'wdth' 100, 'wght' 600`,
                }}
            />
        ))}
      </Breadcrumbs>
  );
}
