import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grow,
  List,
  Paper,
  Stack,
  Typography, Zoom
} from "@mui/material";
import FingerprintIcon from "@mui/icons-material/Fingerprint";

/*Components*/
import WorkExperience from "./components/Experience";
import Education from "./components/Education";
import Player from "../Player";
import { Pricing } from "./components/Pricing";
import { AppSettings } from "../../customization";
import SkeletonCV from "../Skeleton/components/SkeletonCV";
import { Logo } from "../Logo";
import Responsibilities from "./components/Responsibilities";
import { getContrastColor } from "../../lib/helpers";

function CV() {
  const location = useLocation();
  const navigate = useNavigate();
  const stackRef = useRef(null);
  const boxRef = useRef(null);
  const { style } = AppSettings

  /*Statement*/
  const [isLoading, setLoading] = useState(true);
  const [cvData, setCvData] = useState({});
  // const [boxWidth, setBoxWidth] = useState(null);
  // const [stackWidth, setStackWidth] = useState(null);
  const [isVideoVisible, setVideoVisibility] = useState(false);

  // useEffect(() => {
  //     const updateBoxWidth = () => {
  //         console.log("boxRef:", boxRef.current);
  //         if (boxRef.current) {
  //             const width = Math.round(boxRef.current.getBoundingClientRect().width);
  //             setBoxWidth(width);
  //         }
  //     };
  //
  //     const updateStackWidth = () => {
  //         console.log("stackRef:", stackRef.current);
  //         if (stackRef.current) {
  //             const width = Math.round(stackRef.current.getBoundingClientRect().width);
  //             setStackWidth(width - 32);
  //         }
  //     };
  //
  //     updateBoxWidth();
  //     updateStackWidth();
  //
  //     window.addEventListener("resize", updateBoxWidth);
  //     window.addEventListener("resize", updateStackWidth);
  //
  //     return () => {
  //         window.removeEventListener("resize", updateBoxWidth);
  //         window.removeEventListener("resize", updateStackWidth);
  //     };
  //
  // }, []);


  // useEffect(() => {
  //     setVideoVisibility(stackWidth !== boxWidth );
  // }, [boxWidth, stackWidth]);


  /*Effects*/
  useEffect(() => {
    axios({
      url: `https://crm-s.com/api/v1/employees${location.pathname}`,
      params: {
        global_company_name: "Remote Helpers",
      },
    })
        .then((response) => {
          setCvData(response.data.data.employee);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          navigate("/404");
        });
  }, [location.pathname, navigate]);

  const updateVideoVisibility = () => {
    setVideoVisibility(window.innerWidth > 661);
  };

  useEffect(() => {
    // Initial check
    updateVideoVisibility();

    // Add event listener
    window.addEventListener("resize", updateVideoVisibility);

    // Clean up by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateVideoVisibility);
    };
  }, []);

  const bakedPricing = () => {
    const rate = Number(sum_rate_rh);
    const maxHours = 160;

    const pricingArr = [
      {
        title: "Minimal",
        value: +min_price,
        hours: maxHours * 0.25,
        disabled: false,
      },
      {
        title: "Part-time",
        value: +part_price,
        hours: maxHours * 0.5,
        disabled: rate < 0.5,
      },
      {
        title: "Full-time",
        value: +full_price,
        hours: maxHours,
        disabled: rate < 1,
      },
    ];

    return pricingArr.map((item) => ({
      ...item,
      currency: full_symbol,
    }));
  };

  const {
    employee_id,
    short_name,
    position_name,
    image_url,
    skills,
    full_price,
    part_price,
    min_price,
    full_symbol,
    sum_rate_rh,
    work_experiences,
    educations,
    url_video_to_youtube,
    white_label_video,
    languages,
  } = cvData;

  const globalGap = "2.4dvw";

  return (
      isLoading
          ? <SkeletonCV/>
          :
          <Stack ref={stackRef} sx={{
            backgroundColor: "transparent",
            paddingX: '1rem',
            gap: globalGap,
            display: "flex",
            minHeight: '10rem',
            width: '100%',
            flexFlow: "row wrap",
            paddingBottom: '6rem',
            maxWidth: '90rem',
          }}>

            {/*////////////////////Left Side//////////////////////////*/}

            <Box sx={{
              display: "flex",
              minHeight: '10rem',
              minWidth: '280px',
              width: '16%',
              flexGrow: '1',
              flexDirection: 'column',
              gap: globalGap,
            }}>

              {/*////////////////////Employee Card//////////////////////////*/}

              <Box display="flex" sx={{ gap: globalGap, flexFlow: "row wrap", alignItems: "stretch" }}>
                <Grow in>
                  <Card
                      sx={{
                        flexGrow: 1,
                        minWidth: "15rem",
                        flexShrink: "1",
                        width: "27%",
                      }}>
                    <Box component="picture" sx={{
                      width: "100%",
                      aspectRatio: 1,
                      display: "flex",
                      overflow: "hidden",
                      justifyContent: "center",
                      alignItems: "center",
                      background: `${style.cv.cvAvatarBackground}` || 'linear-gradient(90deg,#0f8,#61efff)',
                      transition: "all .2s ease-in-out",

                      userSelect: "none",
                    }}>
                      <CardMedia
                          component="img"
                          image={image_url}
                          alt={`${short_name}-${position_name}`}
                          sx={{
                            objectFit: "cover",
                            userSelect: "none",
                            transition: "all .2s ease-in-out",
                            filter: "drop-shadow(.8rem .8rem .5rem rgba(0,0,0,.2))",
                            "&:hover": {
                              scale: "1.1",
                            },
                          }}
                      />
                    </Box>
                    <CardContent>
                      <Typography
                          variant="h5"
                          color="text.secondary"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            // fontFamily: "unset",
                            // fontWeight: "750",
                            fontVariationSettings: `'wdth' 95, 'wght' 700`,
                            flexFlow: "row wrap",
                          }}>
                        {short_name}
                        {/*`{boxWidth} {stackWidth}`*/}
                        <Chip
                            icon={<FingerprintIcon color={"primary"}/>}
                            label={employee_id}
                            variant="outlined"
                        />
                      </Typography>
                      <Typography
                          variant="button"
                          color="text.secondary"
                          sx={{
                            // fontFamily: "unset",
                            // fontWeight: "700",
                            fontVariationSettings: `'wdth' 80, 'wght' 700`,
                          }}>
                        {position_name}
                      </Typography>
                    </CardContent>
                    <CardActions>

                    </CardActions>
                  </Card>
                </Grow>
              </Box>

              {/*////////////////////Video player//////////////////////////*/}

              {!isVideoVisible &&
                  <Paper sx={{
                    display: "flex",
                    overflow: "hidden",
                    flexGrow: 1,
                    minWidth: "67%",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "10rem",
                    backgroundColor: "#f1f1f1",
                    aspectRatio: "16 / 9",
                  }}>
                    {url_video_to_youtube ? (
                        <Player url={white_label_video ? white_label_video : url_video_to_youtube}/>
                    ) : (
                        <Player url={'https://youtu.be/2VoI4E1I7g0?si=7c2UTDBZI7NNlvJ1'}/>
                        // <Stack
                        //     sx={{
                        //       flexFlow: "column nowrap",
                        //       gap: "2.5rem",
                        //       alignItems: "center",
                        //       justifyContent: 'center',
                        //       width: '100%'
                        //     }}>
                        //   <Logo/>
                        //   <Typography variant={"h6"}>video almost here 😉</Typography>
                        // </Stack>
                    )}
                  </Paper>}

              {/*////////////////////Pricing//////////////////////////*/}

              <Box display="flex" sx={{ gap: globalGap, flexFlow: "row wrap", alignItems: "stretch" }}>
                <Grow in>
                  <Box sx={{ width: "calc(35% - 1rem)", flexGrow: 1, minWidth: "280px" }}>
                    <Pricing pricing={bakedPricing()} data={cvData}/>
                  </Box>
                </Grow>
              </Box>

              {/*////////////////////Skills//////////////////////////*/}

              <Box display="flex" sx={{
                flexDirection: "row",
                gap: globalGap,
                flexWrap: "wrap"
              }}>
                <Paper
                    sx={{
                      // width: "calc(48% - 1rem)",
                      flexGrow: 1,
                      minWidth: "280px",
                      display: "flex",
                      flexFlow: "column nowrap",
                      overflow: "hidden",
                    }}>
                  <Typography
                      variant={"button"}
                      sx={{
                        backgroundColor: style.main.secondaryColor || '#9c27b0',
                        padding: ".7rem 1rem",
                        color: getContrastColor(style.main.secondaryColor || '#9c27b0'),
                      }}>
                    Skills
                  </Typography>
                  <Stack
                      sx={{
                        flexFlow: "row wrap",
                        gap: ".5rem",
                        padding: "1rem",
                      }}>
                    {skills.map((item, index) => (
                        <Zoom in key={index}>
                          <Chip
                              label={item.name}
                              variant="outlined"
                              size={"small"}
                              sx={{
                                fontVariationSettings: `'wdth' 88, 'wght' 500`,
                              }}
                          />
                        </Zoom>
                    ))}
                  </Stack>
                </Paper>
              </Box>

              {/*////////////////////Languages//////////////////////////*/}

              {languages && languages.length > 0 && (
                  <Box display="flex" sx={{ flexDirection: "row", gap: globalGap, flexWrap: "wrap" }}>
                    <Paper
                        sx={{
                          width: "calc(48% - 1rem)",
                          flexGrow: 1,
                          minWidth: "280px",
                          display: "flex",
                          flexFlow: "column nowrap",
                          overflow: "hidden",
                        }}>
                      <Typography
                          variant={"button"}
                          sx={{
                            backgroundColor: style.main.secondaryColor || '#e91e63',
                            padding: ".7rem 1rem",
                            color: getContrastColor(style.main.secondaryColor || '#e91e63'),
                          }}>
                        Languages
                      </Typography>
                      <Stack sx={{
                        padding: '1rem',
                        gap: '1rem',
                        flexFlow: 'row wrap'
                      }}>
                        {languages.map((item, index) => (
                            <Zoom in key={index}>
                              <Chip
                                  label={`${item.name} - ${item.level_short_name} (${item.level_ext_name})`}
                                  variant="outlined"
                                  sx={{
                                    // fontFamily: "unset",
                                    // fontWeight: "450",
                                    fontVariationSettings: `'wdth' 88, 'wght' 500`,
                                    flexGrow: 1,
                                    minWidth: '10rem'
                                  }}
                              />
                            </Zoom>
                        ))}
                      </Stack>
                    </Paper>
                  </Box>
              )}
            </Box>

            {/*////////////////////Right Side//////////////////////////*/}

            <Box ref={boxRef} sx={{
              display: "flex",
              // minHeight: '10rem',
              minWidth: '15rem',
              width: '53%',
              flexGrow: '1',
              flexDirection: 'column',
              gap: globalGap,
            }}>

              {/*////////////////////Video player//////////////////////////*/}

              {isVideoVisible &&
                  <Paper
                      sx={{
                        display: "flex",
                        overflow: "hidden",
                        minWidth: "67%",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "10rem",
                        backgroundColor: "#f1f1f1",
                        aspectRatio: "16 / 9",
                      }}>
                    {url_video_to_youtube ? (
                        <Player url={white_label_video ? white_label_video : url_video_to_youtube}/>
                    ) : (
                        <Player url={'https://youtu.be/2VoI4E1I7g0?si=7c2UTDBZI7NNlvJ1'}/>
                        // <Stack
                        //     sx={{
                        //       flexFlow: "column nowrap",
                        //       gap: "2.5rem",
                        //       alignItems: "center",
                        //       width: '100%'
                        //     }}>
                        //   <Logo/>
                        //   <Typography variant={"h6"}>video almost here 😉</Typography>
                        // </Stack>
                    )}
                  </Paper>
              }

              {/*////////////////////Responsibilities//////////////////////////*/}

              {work_experiences.length > 0 ? (
                  <Box display="flex" sx={{
                    flexDirection: "row",
                    gap: globalGap,
                    flexWrap: "wrap"
                  }}>
                    <Paper
                        sx={{
                          // width: "calc(48% - 1rem)",
                          flexGrow: 1,
                          minWidth: "280px",
                          display: "flex",
                          flexFlow: "column nowrap",
                          overflow: "hidden",
                        }}>
                      <Typography
                          variant={"button"}
                          sx={{
                            backgroundColor: style.main.secondaryColor || '#9c27b0',
                            padding: ".7rem 1rem",
                            color: getContrastColor(style.main.secondaryColor || '#9c27b0'),
                          }}>
                        Responsibilities
                      </Typography>
                      <Stack
                          sx={{
                            flexFlow: "row wrap",
                            gap: ".5rem",
                            padding: "1rem",
                          }}>
                        <Responsibilities workExperiences={work_experiences}/>
                      </Stack>
                    </Paper>
                  </Box>
              ) : null}

              {/*////////////////////Experience//////////////////////////*/}
              {work_experiences && work_experiences.length > 0 && (
                  <Box display="flex" sx={{
                    flexDirection: "row",
                    gap: globalGap,
                    flexWrap: "wrap"
                  }}>

                    <Paper
                        sx={{
                          width: "calc(28% - 1rem)",
                          flexGrow: 1,
                          minWidth: "280px",
                          display: "flex",
                          flexFlow: "column nowrap",
                          overflow: "hidden",
                        }}>
                      <Typography variant={"button"} sx={{
                        backgroundColor: style.main.secondaryColor || '#FFC107',
                        padding: ".7rem 1rem",
                        color: getContrastColor(style.main.secondaryColor || '#FFC107'),
                      }}>
                        Experience
                      </Typography>
                      <List sx={{ width: "100%" }} aria-labelledby="work-experience">
                        {work_experiences
                            .sort((a, b) => {
                              if (!a.end_date_unix && !b.end_date_unix) {
                                return b.start_date_unix - a.start_date_unix;
                              }
                              if (!a.end_date_unix) {
                                return -1;
                              }
                              if (!b.end_date_unix) {
                                return 1;
                              }
                              return b.end_date_unix - a.end_date_unix;
                            })
                            .map((item, index) => (
                                <WorkExperience key={index} item={item}/>
                            ))}
                      </List>
                    </Paper>
                  </Box>
              )}

              {/*////////////////////Education//////////////////////////*/}

              {educations && educations.length > 0 && (
                  <Box display="flex" sx={{ flexDirection: "row", gap: globalGap, flexWrap: "wrap" }}>
                    <Paper
                        sx={{
                          width: "calc(48% - 1rem)",
                          flexGrow: 1,
                          minWidth: "280px",
                          display: "flex",
                          flexFlow: "column nowrap",
                          overflow: "hidden",
                        }}>
                      <Typography
                          variant={"button"}
                          sx={{
                            backgroundColor: style.main.secondaryColor || '#e91e63',
                            padding: ".7rem 1rem",
                            color: getContrastColor(style.main.secondaryColor || '#e91e63'),
                          }}>
                        Education
                      </Typography>
                      <List sx={{ width: "100%" }} aria-labelledby="education">
                        {educations
                            .sort((a, b) => {
                              if (!a.end_date_unix && !b.end_date_unix) {
                                return b.start_date_unix - a.start_date_unix;
                              }
                              if (!a.end_date_unix) {
                                return -1;
                              }
                              if (!b.end_date_unix) {
                                return 1;
                              }
                              return b.end_date_unix - a.end_date_unix;
                            })
                            .map((item, index) => (
                                <Education item={item} key={index}/>
                            ))}
                      </List>
                    </Paper>
                  </Box>
              )}
            </Box>
          </Stack>

  );
}

export { CV };
